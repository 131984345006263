import React from 'react';
import { card } from '../assets';
import styles, { layout } from '../style';
import { Button } from '../components';
import { motion } from "framer-motion";


const container = {
  hidden: { opacity: 0, y: 100 },
  show: {
    y: 0,
    opacity: 1,
    duration: 1.8,
    transition: {
      staggerChildren: 0.7,

    }
  }
}

const item = {
  hidden: { opacity: 0, y: 100 },

  show: {
    opacity: 1,
    y: 0,
  }
}

const CardDeal = () =>
(
  <section className={`${layout.section}`}>
    <motion.div
      variants={container}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true, amount: 0 }}
      className={layout.sectionInfo}>
      <motion.h2
        variants={item}
        className={styles.heading2}>
        Find a better card deal
        <br className="sm:block hidden" />
        in few easy steps.
      </motion.h2>
      <motion.p
        variants={item}
        className={`${styles.paragraph} max-w-[470px] mt-5`}>
        Arcu tortor, purus in mattis at sed integer faucibus. Aliquet quis aliquet eget mauris tortor.ç Aliquet ultrices ac, ametau.
      </motion.p>
      <Button item={item} styles="mt-10" />

    </motion.div>

    <div className={layout.sectionImg}>
      <img src={card} alt="card" className="w-[100%] h-[100%]" />
    </div>
  </section >
)


export default CardDeal