import React from 'react'
import styles from '../style';
import {FeedbackCard } from '../components';
import { feedback } from '../constants'


const Testimonials = () =>
(
  <section id="clients" className={`${styles.paddingY} ${styles.flexCenter} flex-col relative`}>

    <div className="absolute z-[0] w-[60%] h-[60%] -right-[50%] rounded-full blue__gradient" />



    <div className="w-full flex justify-between items-center md:flex-row flex-col sm:mb-16 mb-6 relative z-[1]">
      <h2 className={styles.heading2}>
        What people are
        <br className="sm:block hidden" />
        saying about us
      </h2>
      <div className="w-full md:mt-0 mt-6">
        <p className={`${styles.paragraph} text-left max-w-[470px] `}>
          Everything you need to accept card payments and grow your business anywhere on the planet.
        </p>
      </div>
    </div>
    <div className="w-full flex flex-wrap feedback-container relative z-[1] justify-center">
        {feedback.map((feedback,index) => (
          <FeedbackCard feedback={feedback} {...feedback} index={index} key={feedback.id}/>
        ))}
    </div>
  </section >
)


export default Testimonials