import React from 'react';
import styles from '../style';
import { discount, robot } from '../assets';
import { GetStarted } from '../components';
import { motion } from "framer-motion";


const cardVariants = {
  offscreen: {
    y: 100,
    opacity: 0, scale: 0.5
  },
  onscreen: {
    y: 0,
    opacity: 1,
    scale: 1,
    rotate: 0,
    duration: 0.3,
    ease: [0, 0.71, 0.2, 1.01],
    transition: {
      type: "spring",
      bounce: 0.1,
      duration: 1.8
    }
  }
};
const cardVariants2 = {
  offscreen: {
    y: -100,
    opacity: 0, scale: 0.5
  },
  onscreen: {
    y: 0,
    opacity: 1,
    scale: 1,
    rotate: 0,
    duration: 1.7,
    ease: [0, 0.71, 0.2, 1.01],
    transition: {
      type: "spring",
      bounce: 0.1,
      duration: 3.0
    }
  }
};

const Hero = () => (
  <section id="home" className={`flex md:flex-row flex-col ${styles.paddingY}`}>
    <div className={`flex-1 ${styles.flexStart} flex-col xl:px-0 sm:px-16 px-6`}>
      <div className="flex flex-row items-center py-[6px] px-4 bg-discount-gradient rounded-[10px] mb-2">
        <img src={discount} alt="discount" className="w-[32px] h-[32px]" />
        <p className={`${styles.paragraph} ml-2`}>
          <span className="text-white">20%</span> Discount For {" "}
          <span className="text-white">1 Month</span> Account
        </p>
      </div>

      <div className="flex flex-row justify-between items-center w-full">
        <motion.h1
          variants={cardVariants}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 1 }}
          className="flex-1 font-poppins font-semibold ss:text-[72px] text-[52px] text-white ss:leading-[70px] leading-[55px]">
          The Next
          <br className="sm:block hidden" />{" "}
          <span className="text-gradient">Generation</span>
        </motion.h1>
        <div className="ss:flex hidden md:mr-4 mr-0">
          <GetStarted />
        </div>
      </div>

      <motion.h1
        variants={cardVariants2}
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 1 }}
        className="font-poppins font-semibold ss:text-[68px] text-[52px] text-white ss:leading-[70px] leading-[55px] w-full">
        Payment Method.
      </motion.h1>
      <p className={`${styles.paragraph} max-w-[470px] mt-5`}>Our team of experts uses a methodology to identify the credit cards most likely to fit your needs.
        We examine annual percentage rates, annual fees.</p>
    </div>
    <div className={`flex-1 flex ${styles.flexCenter} md:my-0 my-10 relative`}>
      <img src={robot} alt="billing" className="w-[100%] h-[100%] z-[5] ss:w-[90%]" />
      <div className="absolute z-[0] w-[40%] h-[35%] top-0 pink__gradient"></div>
      <div className="absolute z-[1] w-[80%] h-[80%] rounded-full bottom-40 white__gradient"></div>
      <div className="absolute z-[0] w-[50%] h-[50%] bottom-20 right-20 blue__gradient"></div>
    </div>

    <div className={`ss:hidden ${styles.flexCenter}`}>
      <GetStarted />
    </div>
  </section>
)


export default Hero