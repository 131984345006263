import React from 'react';
import { apple, bill, google } from "../assets";
import styles, { layout } from "../style";
import { motion } from "framer-motion";


const imageVar = {
  offscreen: {
    y: 0,
  },
  onscreen: {
    y: 0,
    rotate: 0,
    transition: {
      type: "spring",
      bounce: 0.6,
      duration: 1.8
    }
  }
};
const imgItem = {
  offscreen: {
    y: 200,
    x: -200,
    rotate: 40,
    opacity: 0, scale: 0.5
  },
  onscreen: {
    y: 0,
    x: 0,
    rotate: 0,
    opacity: 1,
    scale: 1,

    transition: {
      type: "spring",
      bounce: 0.3,
      duration: 3.8,
      ease: "circOut"
    }
  }
}

const container2 = {
  hidden: { opacity: 0, y: 100, x: 100 },
  show: {
    y: 0,
    x:0,
    opacity: 1,
    duration: 2.8,
    transition: {
      type: "spring",
      bounce: 0.6,
    }
  }
}

const item2 = {
  hidden: { opacity: 0, y: 100 },

  show: {
    opacity: 1,
    y: 0,
  }
}



const Billing = () => (







  <section id="product" className={layout.sectionReverse}>
    <motion.div
      variants={imageVar}
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0 }}
      className={layout.sectionImgReverse}>
      <motion.img variants={imgItem} src={bill} alt="bill" className="w-[100%] h-[100%] relative top-50 left-0 -z-1" />

      <div className="absolute z-[3] -left-1/2 top-0 w-[50%] h-[50%] rounded-full white__gradient" />
      <div className="absolute z-[0] w-[50%] h-[50%] -left-1/2 bottom-0 rounded-full pink__gradient" />
    </motion.div>

    <motion.div className={layout.sectionInfo}>
      <motion.h2 
      variants={item2}
      className={styles.heading2}>
        Easily control your <br className="sm:block hidden" /> billing &
        invoicing
      </motion.h2>
      <motion.p 
      variants={item2}
      className={`${styles.paragraph} max-w-[470px] mt-5`}>
        Elit enim sed massa etiam. Mauris eu adipiscing ultrices ametodio
        aenean neque. Fusce ipsum orci rhoncus aliporttitor integer platea
        placerat.
      </motion.p>

      <motion.div
        variants={container2}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0 }}
        className="flex flex-row flex-wrap sm:mt-10 mt-6">
        <motion.img src={apple} alt="google_play" className="w-[128.86px] h-[42.05px] object-contain mr-5 cursor-pointer" />
        <motion.img src={google} alt="google_play" className="w-[144.17px] h-[43.08px] object-contain cursor-pointer" />
      </motion.div>
    </motion.div>
  </section>
);

export default Billing;