import React from 'react';
import { features } from '../constants';
import styles, { layout } from '../style';
import { Button } from '../components';
import { motion } from "framer-motion";


const cardVariants = {
  offscreen: {
    y: 200,
    rotate: 30
  },
  onscreen: {
    y: 0,
    rotate: 0,
    transition: {
      type: "spring",
      bounce: 0.6,
      duration: 1.8
    }
  }
};

const container = {
  hidden: { opacity: 0, y: 100 },
  show: {
    y: 0,
    opacity: 1,
    duration: 1.8,
    transition: {
      staggerChildren: 0.7,

    }
  }
}

const item = {
  hidden: { opacity: 0, y: 100 },

  show: {
    opacity: 1,
    y: 0,
  }
}


const FeatureCard = ({ icon, title, content, index, }) => (

  <motion.div
    initial="offscreen"
    whileInView="onscreen"
    viewport={{ once: true, amount: 0 }}
    className={`flex flex-row p-6 rounded-[20px] 
   ${index !== features.length - 1 ? "mb-6" : "mb-0"} feature-card`}>
    <motion.div variants={cardVariants} className={`w-[64px] h-[64px] rounded-full bg-dimBlue ${styles.flexCenter}`}>
      <img src={icon} alt="icon" className="w-[50%] h-[50%] object-contain" />
    </motion.div>
    <motion.div

      className="flex-1 flex flex-col ml-3">
      <motion.h4
        variants={cardVariants}
        className="font-poppins font-semibold text-white text-[18px] leading-[23px] mb-1">
        {title}
      </motion.h4>
      <motion.p
        variants={cardVariants}
        className="font-poppins font-normal text-dimWhite text-[16px] leading-[24px] mb-1">
        {content}
      </motion.p>
    </motion.div>
  </motion.div>
)




const Business = () => (
  <section id="features" className={layout.section}>
    <motion.div
      variants={container}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true, amount: 0 }}

      className={layout.sectionInfo}>
      <motion.h2
        variants={item}
        className={styles.heading2}>
        You do the business,
        <br className="sm:block hidden" />
        we’ll handle the money.
      </motion.h2>
      <motion.p
        variants={item}
        className={`${styles.paragraph} max-w-[470px] mt-5`}>
        With the right credit card, you can improve your financial life by building credit, earning rewards and saving money. But with hundreds of credit cards on the market.
      </motion.p>
      <Button item={item} styles="mt-10" />
    </motion.div>

    <motion.div

      className={`${layout.sectionImg} flex-col`}>
      {features.map((feature, index) => (
        <FeatureCard key={feature.id} {...feature} index={index} />
      ))}
    </motion.div>
  </section >
)


export default Business