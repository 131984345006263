import React from 'react';
import styles from '../style';
import { arrowUp } from '../assets';
import { motion } from "framer-motion";


const GetStarted = () => (
  <motion.div
    drag
    dragTransition={{
      power: 0,
      modifyTarget: target => Math.round(target / 50) * 50
    }}
    initial={{ opacity: 0, scale: 0.5, rotate: 425 }}
    animate={{ opacity: 1, scale: 1, rotate: 0 }}
    transition={{
      default: {
        duration: 1.3,
        ease: "anticipate"
      },
      scale: {
        type: "spring",
        damping: 7,
        stiffness: 100,
        restDelta: 0.001
      }
    }}
    className={`${styles.flexCenter} 
     w-[140px] h-[140px] rounded-full bg-blue-gradient p-[2px] cursor-pointer`}>
    <div className={`${styles.flexCenter} flex-col bg-primary w-[100%] h-[100%] rounded-full gets`}>
      <div className={`${styles.flexStart} flex-row`}>
        <p className="font-poppins font-medium text-[18px] leading-[23px]">
          <span className="text-gradient hover:text-black">Get</span>
        </p>
        <img src={arrowUp} alt="arrow" className="w-[23px] h-[23px] object-contain hover:rotate-45" />
      </div>
      <p className="font-poppins font-medium text-[18px] leading-[23px]">
        <span className="text-gradient hover:text-black">Started</span>
      </p>
    </div>
  </motion.div>
)


export default GetStarted